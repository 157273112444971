import React, { useState, useEffect, useCallback } from "react";
import { ModalSimple } from "../../components/Modal";
import { ModalConfirm } from "../../components/ModalConfirm";
import { FaMapMarkerAlt } from "react-icons/fa";
import CardGroup from "react-bootstrap/CardGroup";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import InputMask from "react-input-mask";
import Card from "react-bootstrap/Card";
import { useSnackbar } from "notistack";

//components
import CardTextCNAEsecundariaExtrato from "../../components/CardTextCNAESecundariaExtrato";
import CardTextSocioExtrato from "../../components/CardTextSocioExtrato";
import { ButtonSearchMobile } from "./components/ButtonSearchMobile";
import CardHeaderExtrato from "../../components/CardHeaderExtrato";
import { importaEmpresa, reservaEmpresa } from "../../utils/APIImportaEmpresa";
import { importaEmpresaWebApi } from "../../utils/APIImportaEmpresaWEBAPI";

import CardTextExtrato from "../../components/CardTextExtrato";
import CardTextComplement from "../../components/CardTextComplement";
import AlertLicense from "../../components/AlertLicense";

//Services
// import ClienteService from "../../services/clienteEmpresaService";
import ComplementService from "../../services/complementService";
import EmpresaService from "../../services/empresaService";
import ExtratoCreditosService from "../../services/extratoCreditosService";

//icons e images
import PesquisarSemResult from "../../assets/pesquisa_sem_resultado.svg";
import GooglePlus from "../../assets/google-plus.svg";
import Pesquisar from "../../assets/Pesquisar.svg";
import Linkedin from "../../assets/linkedin.svg";
import { BsJournalPlus } from "react-icons/bs";
import Twitter from "../../assets/twitter.svg";
import { BiImport } from "react-icons/bi";
import { FiAnchor } from "react-icons/fi";
import { FiLock } from "react-icons/fi";
import { FaUserLock } from "react-icons/fa";

//css
import "bootstrap/dist/css/bootstrap.min.css";
import "./extratoStyledPage.css";
import integracaoEmpresaService from "../../services/integracaoEmpresaService";
import CardTextRegimeTributario from "../../components/CardTextRegimeTributario";
import CardTextSimplesNacional from "../../components/CardTextSimplesNacional";

const Extrato = (cnpjParam) => {
  let history = useHistory();
  const [cookies, setCookie] = useCookies([""]);
  const mascaraCNPJ = "99.999.999/9999-99";
  const [cnpj, setCnpj] = useState(
    cnpjParam === undefined ? cookies.cnpjExtrato : cnpjParam.cnpjParam
  );

  const [razaoSocial, setRazaoSocial] = useState("");
  const [naturezaJuridica, setNaturezaJuridica] = useState("");
  const [descricaoNaturezaJuridica, setDescricaoNaturezaJuridica] =
    useState("");
  const [qualificacaoResponsavel, setQualificacaoResponsavel] = useState("");
  const [
    descricaoQualificacaoResponsavel,
    setDescricaoQualificacaoResponsavel,
  ] = useState("");
  const [motivoSCadastral, setMotivoSCadastral] = useState("");
  const [descricaoMotivoSCadastral, setDescricaoMotivoSCadastral] =
    useState("");
  const [dataInicioAtividade, setDataInicioAtividade] = useState("");
  const [situacaoEspecial, setSituacaoEspecial] = useState("");
  const [dataSituacaoEspecial, setDataSituacaoEspecial] = useState("");
  const [fantasia, setFantasia] = useState("");
  const [totalDeFiliais, setTotalDeFiliais] = useState("");
  const [identificadorMF, setIdentificadorMF] = useState("");
  const [situacaoCadastral, setSituacaoCadastral] = useState("");
  const [dataSituacaoCadastral, setDataSituacaoCadastral] = useState("");
  const [cnae, setCnae] = useState("");
  const [capitalSocial, setCapitalSocial] = useState("");
  const [porteEmpresa, setPorteEmpresa] = useState("");
  const [telefone1, setTelefone1] = useState("");
  const [telefone2, setTelefone2] = useState("");
  const [email, setEmail] = useState("");
  const [tipoLogradouro, setTipoLogradouro] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numeroLogradouro, setNumeroLogradouro] = useState("");
  const [complementoLogradouro, setComplementoLogradouro] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [codigoUF, setCodigoUf] = useState("");
  const [maps, setMaps] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [google, setGoogle] = useState("");
  const [twitter, setTwitter] = useState("");
  const [empresaCigam, setEmpresaCigam] = useState("");
  const [nomeSocio, setNomeSocio] = useState([]);
  const [cnaeSecundaria, setCnaeSecundaria] = useState([]);
  const [regimeTributario, setRegimeTributario] = useState([]);
  const [dadosSimples, setDadosSimples] = useState([]);
  const [encontrado, setEncontrado] = useState(null);
  const cnpjLogado = localStorage.getItem("CNPJ");
  const tokenAPI = localStorage.getItem("TOKENAPI");
  const [showModal, setShowModal] = useState(false);
  const [complementsEmpresa, setComplementsEmpresa] = useState([]);
  const [complementsContato, setComplementsContato] = useState([]);
  const [complementsQuadroSocios, setComplementsQuadroSocios] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [attribute, setAttribute] = useState("");
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalReservaImport, setShowModalReservaImport] = useState(false);
  const [showModalReserva, setShowModalReserva] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const urlIntegration = localStorage.getItem("URLINTEGRATION");
  const { enqueueSnackbar } = useSnackbar();

  const openModalConfirm = () => {
    setShowModalConfirm((prev) => !prev);
  };

  const openModalReserva = () => {
    setShowModalReserva((prev) => !prev);
  };

  const openModalReservaImport = () => {
    setShowModalReservaImport((prev) => !prev);
  };

  const openModal = useCallback((props) => {
    setShowModal((prev) => !prev);
  }, []);

  const [permissao] = useState(localStorage.getItem("LIBERADO_PORTAL"));
  const [reserva, setReserva] = useState([]);

  const handleFind = async () => {
    var cnpjSemCaracteres = cnpj;
    cnpjSemCaracteres = cnpjSemCaracteres.replace(/\./g, "");
    cnpjSemCaracteres = cnpjSemCaracteres.replace("-", "");
    cnpjSemCaracteres = cnpjSemCaracteres.replace("/", "");

    if (cnpjSemCaracteres !== undefined && cnpjSemCaracteres.trim() !== "") {
      setCookie("cnpjExtrato", cnpjSemCaracteres, { path: "/" });

      const empresas = await EmpresaService.getEmpresa(cnpjSemCaracteres);

      if (cnpjLogado === "93578813000144") setReserva(empresas.reserva);

      if (empresas.data.length > 0) {
        ExtratoCreditosService.postExtratoCreditos({
          creditosUtilizados: 1,
          tipo: "pesquisa",
          cnpj: cnpjSemCaracteres,
          razaoSocial: empresas.data[0].razaoSocial,
        });
        console.log(empresas);
        const response = await EmpresaService.formataEmpresa(empresas);
        setEncontrado(true);
        if (response !== undefined && response !== null) {
          setEncontrado(true);
          setCnpj(cnpjSemCaracteres);
          setRazaoSocial(response.razaoSocial);

          setNaturezaJuridica(response.naturezaJuridica);
          setDescricaoNaturezaJuridica(response.descricaonaturezaJuridica);
          setQualificacaoResponsavel(response.qualificacaoResponsavel);
          setDescricaoQualificacaoResponsavel(
            response.descricaoqualificacaoResponsavel
          );
          setMotivoSCadastral(response.motivoScadastral);
          setDescricaoMotivoSCadastral(response.descricaomotivoScadastral);
          setDataInicioAtividade(response.dataInicioAtividade);
          setSituacaoEspecial(response.situacaoEspecial);
          setDataSituacaoEspecial(response.dataSituacaoEspecial);

          setFantasia(response.fantasia);
          setTotalDeFiliais(response.totalDeFiliais);
          setIdentificadorMF(response.identificadorMF);
          setSituacaoCadastral(response.situacaoCadastral);
          setDataSituacaoCadastral(response.dataSituacaoCadastral);
          setCnae(response.cnae);
          setCapitalSocial(response.capitalSocial);
          setPorteEmpresa(response.porteEmpresa);

          response.telefone1 === "() "
            ? setTelefone1("")
            : setTelefone1(response.telefone1);
          response.telefone2 === "() "
            ? setTelefone2("")
            : setTelefone2(response.telefone2);
          setEmail(response.email);
          setMaps(response.maps);
          setGoogle(response.google);
          setTwitter(response.twitter);
          setLinkedin(response.linkedin);
          setMunicipio(response.municipio);
          setTipoLogradouro(response.tipoLogradouro);
          setLogradouro(response.logradouro);
          setNumeroLogradouro(response.numeroLogradouro);
          setComplementoLogradouro(response.complementoLogradouro);
          setCep(response.cep);
          setUf(response.uf);
          setCodigoUf(response.codigoUF);
          setBairro(response.bairro);

          setEmpresaCigam(response.empresaCigam);
          !response.socios ? setNomeSocio([]) : setNomeSocio(response.socios);

          !response.cnaeSecundaria
            ? setCnaeSecundaria([])
            : setCnaeSecundaria(response.cnaeSecundaria);
          !response.regimeTributario
            ? setRegimeTributario([])
            : setRegimeTributario(response.regimeTributario);
          //simples nacional
          !response.dadosSimples
            ? setDadosSimples([])
            : setDadosSimples(response.dadosSimples);

          searchComplements();
          searchAttributes();
        }
      } else {
        setEncontrado(false);
      }
    }
  };

  useEffect(() => {
    if (!!cnpj) {
      if (cnpj.replace(/[^0-9]/g, "").length === 14) handleFind();
    }
  }, [cnpj]);

  function importarEmpresa() {
    openModalConfirm();
  }

  useEffect(() => {
    if (confirm) {
      document.getElementById("btn").disabled = true;
      document.getElementById("btn").style.opacity = 0.1;

      setTimeout(function () {
        toggleDisabled();
      }, 2000);

      function toggleDisabled() {
        document.getElementById("btn").disabled = false;
        document.getElementById("btn").style.opacity = 0.9;
      }
      const importEmp = async () => {
        let optanteSimples = false;
        if (dadosSimples != null && dadosSimples != undefined) {
          const dadosOp = dadosSimples[0];
          if (dadosOp !== null) {
            optanteSimples = dadosOp.opcao_simples === "S";
          }
        }

        if (
          urlIntegration === undefined ||
          urlIntegration === "" ||
          urlIntegration === null
        ) {
          var empInt = [{ cnpj: cnpj }];
          const result = await integracaoEmpresaService
            .postIntegracaoEmpresa(empInt)
            .then((res) => {
              enqueueSnackbar("Empresa importada com sucesso", {
                variant: "success",
              });
            })
            .catch((error) => {
              enqueueSnackbar("Ocorreu um erro ao importar", {
                variant: "error",
              });
            })
            .finally(() => {});
        } else {
          if (
            tokenAPI !== undefined &&
            tokenAPI !== null &&
            tokenAPI !== "" &&
            cnpjLogado !== "93578813000144"
          ) {
            setEmpresaCigam(
              importaEmpresaWebApi(
                razaoSocial,
                telefone1,
                telefone2,
                logradouro,
                bairro,
                municipio,
                codigoUF,
                cep,
                cnpj,
                fantasia,
                capitalSocial,
                numeroLogradouro,
                complementoLogradouro,
                cnae.cnaeFiscal,
                optanteSimples
              )
            );
          } else {
            if (cnpjLogado === "93578813000144") {
              importaEmpresa(
                razaoSocial,
                telefone1,
                telefone2,
                logradouro,
                bairro,
                municipio,
                codigoUF,
                cep,
                cnpj,
                fantasia,
                capitalSocial,
                numeroLogradouro,
                complementoLogradouro,
                cnae.cnaeFiscal
              );
            } else {
              enqueueSnackbar(
                "Ocorreu um erro ao importar, verifique as permissões do usuário",
                {
                  variant: "error",
                }
              );
            }
          }
        }
      };

      importEmp();
      setConfirm(false);
    }
  }, [confirm]);

  function historyPushRedirect(p_raizCnpj) {
    setCookie("raizCnpj", p_raizCnpj, { path: "/" });
    history.push("/pm/pesquisa");
  }
  function handleKeyPressEnter(event) {
    if (event.key === "Enter") {
      handleFind();
    }
  }
  const searchAttributes = async () => {
    const getAttributes = await ComplementService.getAttributes();
    setAttribute(getAttributes);
  };

  const searchComplements = async () => {
    var cnpjSemCaracteres = cnpj;
    if (!!cnpjSemCaracteres) {
      cnpjSemCaracteres = cnpjSemCaracteres.replace(/\./g, "");
      cnpjSemCaracteres = cnpjSemCaracteres.replace("-", "");
      cnpjSemCaracteres = cnpjSemCaracteres.replace("/", "");
    }

    const complementsCNPJ = await ComplementService.getComplementCNPJ(
      cnpjSemCaracteres
    );

    var cardEmpresa = complementsCNPJ.filter((e) => e.namecard === "Empresa");
    var cardContato = complementsCNPJ.filter((e) => e.namecard === "Contato");

    var cardQuadroSocios = complementsCNPJ.filter(
      (e) => e.namecard === "Quadro Societário"
    );

    setComplementsEmpresa(cardEmpresa);
    setComplementsContato(cardContato);
    setComplementsQuadroSocios(cardQuadroSocios);
  };

  useEffect(() => {
    if (!refreshList) {
      searchComplements();
      searchAttributes();
    }

    setRefreshList(false);
  }, [refreshList]);

  return (
    <>
      <ModalConfirm
        showModalConfirm={showModalConfirm}
        setShowModalConfirm={setShowModalConfirm}
        setConfirm={setConfirm}
        message="Tem certeza que deseja importar a empresa selecionada?"
      />

      {reserva !== undefined &&
        reserva.length > 0 &&
        cnpjLogado === "93578813000144" && (
          <>
            <ModalConfirm
              showModalConfirm={showModalReserva}
              setShowModalConfirm={setShowModalReserva}
              setConfirm={() => {}}
              //message={`Empresa reservado para ${reserva[0].Rep_Fantasia}, pelo vendedor ${reserva[0].Ven_Fantasia}.`}
              okButton={true}
              messages={[`Empresa reservada para ${reserva[0].Rep_Fantasia}.`]}
              headerMessage={"Informamos:"}
            />
          </>
        )}

      {cnpjLogado === "93578813000144" && (
        <ModalConfirm
          showModalConfirm={showModalReservaImport}
          setShowModalConfirm={setShowModalReservaImport}
          setConfirm={async (e) => {
            if (e === true) {
              await reservaEmpresa(
                razaoSocial,
                telefone1,
                telefone2,
                logradouro,
                bairro,
                municipio,
                codigoUF,
                cep,
                cnpj,
                fantasia,
                capitalSocial,
                numeroLogradouro,
                complementoLogradouro,
                cnae.cnaeFiscal,
                reserva
              );

              handleFind();
            }
          }}
          message="Tem certeza que deseja reservar a empresa selecionada?"
        />
      )}
      <ModalSimple
        showModal={showModal}
        setShowModal={setShowModal}
        cnpjSelected={cnpj}
        setRefreshList={setRefreshList}
        attributes={attribute}
      />
      {cnpjParam === undefined && permissao !== "S" ? (
        <AlertLicense></AlertLicense>
      ) : null}
      <div className="top-bar-extrato">
        <div className="top-bar-titles-extrato">
          <p>Relatório individual da empresa</p>
          <h3>Dados Empresa</h3>
        </div>
        <div className="top-bar-search">
          <button className="button-search" onClick={() => handleFind()}>
            <img src={Pesquisar} alt=""></img>
          </button>

          <InputMask
            mask={mascaraCNPJ}
            className="inputTextSearch"
            type="text"
            placeholder="Pesquise por CNPJ"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            onKeyPress={(e) => handleKeyPressEnter(e)}
          />
        </div>

        <div className="top-bar-search-mobile">
          <div className="ButtonSearchMobile">
            <ButtonSearchMobile
              handleKeyPressEnter={handleKeyPressEnter}
              handleFind={handleFind}
            />
          </div>

          <div className="top-bar-import">
            {cnpj !== undefined ? (
              <div className="button-complement">
                <button
                  id={"complement"}
                  className="btn-import"
                  onClick={() => openModal()}
                  title={"Adicionar complementos"}
                >
                  <BsJournalPlus alt="" className="iconPlus" />
                  <br />
                  Inf.Comp
                </button>
              </div>
            ) : null}

            {urlIntegration !== undefined &&
            urlIntegration !== "" &&
            urlIntegration !== null ? (
              <div className="button-import">
                <button
                  id={"btn"}
                  className="btn-import"
                  title={"Código da empresa " + empresaCigam}
                  onClick={() => importarEmpresa()}
                >
                  <BiImport alt="" className="iconCard" />
                  <br />
                  Importar
                </button>
              </div>
            ) : (
              <> </>
            )}

            {encontrado === true &&
              cnpjLogado === "93578813000144" &&
              reserva !== undefined && (
                <div className="button-import">
                  <button
                    id={"btnReservar"}
                    className="btn-reserv"
                    title={
                      reserva.length > 0 && reserva[0].DIVISAO !== "P"
                        ? reserva[0].DIVISAO.trim() === "RES"
                          ? "Reservado"
                          : "Cliente"
                        : "Reservar"
                    }
                    onClick={() => {
                      if (reserva.length > 0) {
                        if (
                          reserva[0].DIVISAO.trim() === "RES" ||
                          reserva[0].DIVISAO.trim() !== "P"
                        ) {
                          openModalReserva();
                        } else {
                          openModalReservaImport();
                        }
                      } else {
                        console.log("SHOWW");
                        openModalReservaImport();
                      }
                    }}
                  >
                    {reserva !== undefined &&
                    reserva.length > 0 &&
                    reserva[0].DIVISAO.trim() !== "P" ? (
                      <>
                        {reserva[0].DIVISAO.trim() === "RES" ? (
                          <FiLock alt="" className="iconCard" />
                        ) : (
                          <FaUserLock alt="" className="iconCard" />
                        )}
                      </>
                    ) : (
                      <FiAnchor alt="" className="iconCard" />
                    )}
                    <br />
                    {reserva !== undefined &&
                    reserva.length > 0 &&
                    reserva[0].DIVISAO.trim() !== "P"
                      ? reserva[0].DIVISAO.trim() === "RES"
                        ? "Reservado"
                        : "Cliente"
                      : "Reservar"}
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
      {encontrado === null ? (
        <CardGroup>
          <Card className="card-primary-result">
            <div className="primary-result">
              <p className="title">
                Inicie sua consulta de empresa com a pesquisa de CNPJ acima!!
              </p>
              <img src={PesquisarSemResult} alt="Pesquisar" />
            </div>
          </Card>
        </CardGroup>
      ) : !encontrado ? (
        <CardGroup>
          <Card className="card-no-result">
            <div className="no-result">
              <p className="title">Nenhum resultado para "{cnpj}"</p>
              <img src={PesquisarSemResult} alt="Sem resultado" />
              <p className="subtitle">Tente novamente com outro CNPJ!</p>
            </div>
          </Card>
        </CardGroup>
      ) : (
        <>
          <CardGroup>
            <Card className="card-empresa">
              <CardHeaderExtrato
                title={"Empresa"}
                subtitle={"Principais informações"}
              />
              <Card.Body>
                <CardTextExtrato
                  subtitle={"Razão Social"}
                  value={razaoSocial}
                />
                <CardTextExtrato subtitle={"CNPJ"} value={cnpj} />

                {fantasia === "" ? null : (
                  <CardTextExtrato subtitle={"Fantasia"} value={fantasia} />
                )}

                <Card.Subtitle>Total de Unidades de negócio</Card.Subtitle>
                <div className="filiais  ">
                  <p className="text_card">{totalDeFiliais}</p>{" "}
                  {permissao === "S" ? (
                    <div
                      className="totalDeFiliais"
                      onClick={() => historyPushRedirect(cnpj.substring(0, 7))}
                    >
                      Ver unidades
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <CardTextExtrato
                  subtitle={"IdentificadorMF"}
                  value={identificadorMF}
                />
                {situacaoCadastral === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Situação Cadastral"}
                    value={situacaoCadastral}
                  />
                )}
                {dataSituacaoCadastral === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Data situação cadastral"}
                    value={dataSituacaoCadastral}
                  />
                )}
                <CardTextExtrato subtitle={"CNAE"} value={cnae.cnaePrincipal} />

                {dataInicioAtividade === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Inicio da atividade"}
                    value={dataInicioAtividade}
                  />
                )}
                {situacaoEspecial === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Situação especial"}
                    value={situacaoEspecial}
                  />
                )}
                {dataSituacaoEspecial === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Data da situação especial"}
                    value={dataSituacaoEspecial}
                  />
                )}

                {descricaoMotivoSCadastral === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Descrição do motivo da situação cadastral"}
                    value={descricaoMotivoSCadastral}
                  />
                )}

                {complementsEmpresa.map((complement, index) => (
                  <>
                    <CardTextComplement
                      key={index}
                      id={complement.id_atribute}
                      value={complement.values}
                      ultimoElementoDoCard={true}
                      idComplement={complement._id}
                      qtdLikes={complement.qtdeLikes}
                      qtdDislikes={complement.qtdeDisLikes}
                      like={complement.usuarioCurtiu}
                      dislike={complement.usuarioDislike}
                      setRefreshList={setRefreshList}
                      attribute={attribute}
                    />
                  </>
                ))}
              </Card.Body>
            </Card>
            <br></br>
            <Card className="card-empresa">
              <CardHeaderExtrato
                title={"Contato"}
                subtitle={"Telefone/E-mail"}
              />
              <Card.Body>
                {telefone1 === "" ? null : (
                  <CardTextExtrato subtitle={"Telefone"} value={telefone1} />
                )}

                {telefone2 === "" ? null : (
                  <CardTextExtrato subtitle={"Telefone 2"} value={telefone2} />
                )}

                {email === "" ? null : (
                  <CardTextExtrato subtitle={"E-mail"} value={email} />
                )}

                <Card.Subtitle>Externos</Card.Subtitle>

                <div className="iconCard">
                  <a href={google} className="LinksExternos">
                    <img
                      src={GooglePlus}
                      alt=""
                      className="iconCard"
                      style={{ color: "#307DA2" }}
                    />
                    Google
                  </a>
                  <br />
                  <a
                    href={twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    alt=""
                    className="LinksExternos"
                  >
                    <img
                      src={Twitter}
                      alt=""
                      className="iconCard"
                      style={{ color: "#307DA2" }}
                    />
                    Twitter
                  </a>
                  <br />
                  <a
                    href={linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    alt=""
                    className="LinksExternos"
                  >
                    <img
                      src={Linkedin}
                      alt=""
                      className="iconCard"
                      style={{ color: "#307DA2" }}
                    />
                    Linkedin
                  </a>
                </div>
                <hr />
                {complementsContato.map((complement, index) => (
                  <CardTextComplement
                    key={index}
                    id={complement.id_atribute}
                    value={complement.values}
                    ultimoElementoDoCard={true}
                    idComplement={complement._id}
                    qtdLikes={complement.qtdeLikes}
                    qtdDislikes={complement.qtdeDisLikes}
                    like={complement.usuarioCurtiu}
                    dislike={complement.usuarioDislike}
                    setRefreshList={setRefreshList}
                    attribute={attribute}
                  />
                ))}
              </Card.Body>
            </Card>
            <br></br>
            <Card className="card-empresa">
              <CardHeaderExtrato
                title={"Situação Tributária"}
                subtitle={""}
                openModal={() => openModal({ title: "Situação Tributária" })}
              />
              <Card.Body>
                <CardTextExtrato
                  subtitle={"Capital Social"}
                  value={capitalSocial}
                />
                <CardTextExtrato
                  subtitle={"Porte Empresa"}
                  value={porteEmpresa}
                />

                {descricaoQualificacaoResponsavel === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Qualificação do responsável"}
                    value={descricaoQualificacaoResponsavel}
                  />
                )}

                {descricaoNaturezaJuridica === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Descrição da natureza jurídica"}
                    value={descricaoNaturezaJuridica}
                  />
                )}

                {regimeTributario.length === 0 ? null : (
                  <CardTextRegimeTributario
                    listaRegimeTributario={regimeTributario}
                  />
                )}

                {dadosSimples !== undefined && dadosSimples.length > 0 && (
                  <CardTextSimplesNacional dadosSimples={dadosSimples} />
                )}
              </Card.Body>
            </Card>
          </CardGroup>
          <CardGroup>
            <Card className="card-empresa">
              <CardHeaderExtrato
                title={"Atividade"}
                subtitle={"Estrutura detalhada"}
                openModal={() => openModal({ title: "Atividade" })}
              />
              <Card.Body>
                <CardTextExtrato subtitle={"Seção"} value={cnae.secao} />
                <CardTextExtrato subtitle={"Divisão"} value={cnae.divisao} />
                <CardTextExtrato subtitle={"Grupo"} value={cnae.grupo} />
                <CardTextExtrato subtitle={"Classe"} value={cnae.classe} />
                <CardTextExtrato
                  subtitle={"Subclasse - CNAE"}
                  value={cnae.cnaePrincipal}
                />
              </Card.Body>
            </Card>
            <br></br>
            <Card className="card-empresa">
              <CardHeaderExtrato
                title={"Localização"}
                subtitle={"Endereço detalhado"}
              />
              <Card.Body>
                <Card.Subtitle>
                  Tipo logradouro / Logradouro / Número Logradouro / Bairro
                </Card.Subtitle>
                <Card.Text className="enderecoCompleto">
                  <b className="text_card">{tipoLogradouro} </b>
                  <b className="text_card"> {logradouro}</b>
                  <b className="text_card"> {numeroLogradouro}</b>
                  <b className="text_card">, {bairro} </b>
                  <a href={maps} id="maps" className="text_card">
                    <FaMapMarkerAlt style={{ color: "#3b96c2" }} />
                  </a>
                </Card.Text>

                {complementoLogradouro === "" ? null : (
                  <CardTextExtrato
                    subtitle={"Complemento Logradouro"}
                    value={complementoLogradouro}
                  />
                )}
                <CardTextExtrato subtitle={"CEP"} value={cep} />
                <CardTextExtrato subtitle={"Município"} value={municipio} />
                <CardTextExtrato subtitle={"Estado"} value={uf} />
              </Card.Body>
            </Card>
            <br></br>
            <Card className="card-empresa">
              <CardHeaderExtrato
                title={"Quadro Societário"}
                subtitle={"Nomes dos sócios"}
              />
              <Card.Body>
                <CardTextSocioExtrato
                  Arraysocios={nomeSocio}
                  refreshList={refreshList}
                  setRefreshList={setRefreshList}
                  cnpj={cnpj}
                  complements={complementsQuadroSocios}
                  attribute={attribute}
                ></CardTextSocioExtrato>
              </Card.Body>
            </Card>
          </CardGroup>
          <CardGroup className="umCard">
            <Card className="card-empresa">
              <CardHeaderExtrato
                title="CNAE's"
                subtitle={"Atividades complementares"}
              />
              <Card.Body>
                <CardTextCNAEsecundariaExtrato
                  ArrayCnaeSecundaria={cnaeSecundaria}
                />
              </Card.Body>
            </Card>
          </CardGroup>
        </>
      )}
    </>
  );
};
export default Extrato;
